import { Box, Container, HStack, Heading, Image, Tag, Text } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { memo } from "react"

import type { Props } from "@app/templates/store"
import type { PageProps } from "@root/types/global"

const Store: React.FC<PageProps<Props>> = ({ store }) => {
  const { getContent } = useContent()
  const item = {
    ...store,
    image: {
      alt: store?.image?.alt || "",
      src: store?.image?.asset?.url || "",
    },
    content: getContent({ content: store?.content }),
  }
  return (
    <Container maxW="container.md" minH="calc(100vh - 540px)">
      <Box as="article" py={8} textAlign="center">
        <Heading as="h1" mb={4}>
          {item?.title}
        </Heading>

        {item?.description && <Text>{item?.description}</Text>}
        {item?.phone && <Text>Phone: {item?.phone}</Text>}
        {item?.email && <Text>Email: {item?.email}</Text>}
        {item?.address && <Text>Address: {item?.address}</Text>}

        <HStack align="center">
          {item?.openhours?.length > 0 &&
            item?.openhours.map((openhour, index) => (
              <Tag key={index}>
                {openhour.day}: {openhour.hours}
              </Tag>
            ))}
        </HStack>

        {item?.image?.src && <Image boxSize="100%" objectFit="cover" src={item?.image?.src} alt={item?.image?.alt} my={8} />}

        <Box align="left" mb={8}>
          {item?.content}
        </Box>
      </Box>
    </Container>
  )
}

export default memo(Store)
